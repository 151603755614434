<template>
    <div class="menu-panel">
      <ul class="menu">
        <!-- При клике на каждый пункт создается модальное окно с определенным компонентом -->
        <li @click="createModal('Информация о разработчиках', DevelopersInfo)">Разработчики</li>
        <li @click="createModal('Наши проекты', ProjectsInfo)">Решения</li>
        <li @click="createModal('Статус завершенности и информация по серверам', ServersInfo)">Игровые серверы</li>

      </ul>
    </div>
  
    <div class="container">
      <canvas ref="bgCanvas"></canvas>
      <canvas ref="textCanvas"></canvas>
    </div>
  
    <div
      v-for="(modal, index) in modals"
      :key="modal.id"
      class="modal-window"
      :style="modalStyle(modal)"
      @mousedown="bringToFront(index)"
    >
      <div class="modal-header" @mousedown="startDrag(index, $event)">
        <div class="modal-title">{{ modal.content }}</div>
        <div class="modal-buttons">
          <button @click.stop="toggleMinimize(index)">_</button>
          <button @click.stop="toggleMaximize(index)">[ ]</button>
          <button @click.stop="closeModal(index)">X</button>
        </div>
      </div>
      <div class="modal-body" v-show="!modal.minimized">
        <component :is="modal.component"></component>
      </div>
      <div class="resize-handle resize-right" @mousedown="startResize(index, 'right', $event)"></div>
      <div class="resize-handle resize-bottom" @mousedown="startResize(index, 'bottom', $event)"></div>
      <div class="resize-handle resize-corner" @mousedown="startResize(index, 'corner', $event)"></div>
    </div>
  </template>
  
  <script setup>
  import { onMounted, onBeforeUnmount, ref } from 'vue'
  
  import DevelopersInfo from '../components/DevelopersInfo.vue'
  import ProjectsInfo from '../components/ProjectsInfo.vue'
  import ServersInfo from '../components/ServersInfo.vue'
  import HalfLifeConsole from '../components/HalfLifeConsole.vue'
  
  const modals = ref([])
  let zCounter = 10001
  let dragInfo = null
  let resizeInfo = null
  
  function createModal(title, component) {
    const width = 800
    const height = 600
    const left = (window.innerWidth - width) / 2
    const top = (window.innerHeight - height) / 2
    modals.value.push({
      id: Date.now(),
      content: title,
      component: component,
      left,
      top,
      width,
      height,
      minimized: false,
      maximized: false,
      zIndex: zCounter++
    })
  }
  
  function closeModal(index) {
    modals.value.splice(index, 1)
  }
  
  function bringToFront(index) {
    modals.value[index].zIndex = ++zCounter
  }
  
  function toggleMinimize(index) {
    const modal = modals.value[index]
    modal.minimized = !modal.minimized
  }
  
  function toggleMaximize(index) {
    const modal = modals.value[index]
    if (!modal.maximized) {
      modal.oldLeft = modal.left
      modal.oldTop = modal.top
      modal.oldWidth = modal.width
      modal.oldHeight = modal.height
  
      modal.left = 0
      modal.top = 0
      modal.width = window.innerWidth
      modal.height = window.innerHeight
      modal.maximized = true
      modal.minimized = false
    } else {
      modal.left = modal.oldLeft
      modal.top = modal.oldTop
      modal.width = modal.oldWidth
      modal.height = modal.oldHeight
      modal.maximized = false
    }
  }
  
  function startDrag(index, event) {
    bringToFront(index)
    const modal = modals.value[index]
    if (modal.minimized) return
    dragInfo = {
      index,
      startX: event.clientX,
      startY: event.clientY,
      startLeft: modal.left,
      startTop: modal.top
    }
    window.addEventListener('mousemove', onDrag)
    window.addEventListener('mouseup', stopDrag)
  }
  
  function onDrag(event) {
    if (!dragInfo) return
    const modal = modals.value[dragInfo.index]
    const dx = event.clientX - dragInfo.startX
    const dy = event.clientY - dragInfo.startY
    modal.left = dragInfo.startLeft + dx
    modal.top = dragInfo.startTop + dy
  }
  
  function stopDrag() {
    window.removeEventListener('mousemove', onDrag)
    window.removeEventListener('mouseup', stopDrag)
    dragInfo = null
  }
  
  function startResize(index, direction, event) {
    bringToFront(index)
    const modal = modals.value[index]
    resizeInfo = {
      index,
      direction,
      startX: event.clientX,
      startY: event.clientY,
      startWidth: modal.width,
      startHeight: modal.height
    }
    window.addEventListener('mousemove', onResize)
    window.addEventListener('mouseup', stopResize)
    event.stopPropagation()
  }
  
  function onResize(event) {
    if (!resizeInfo) return
    const modal = modals.value[resizeInfo.index]
    const dx = event.clientX - resizeInfo.startX
    const dy = event.clientY - resizeInfo.startY
    if (resizeInfo.direction === 'right') {
      modal.width = resizeInfo.startWidth + dx
    } else if (resizeInfo.direction === 'bottom') {
      modal.height = resizeInfo.startHeight + dy
    } else if (resizeInfo.direction === 'corner') {
      modal.width = resizeInfo.startWidth + dx
      modal.height = resizeInfo.startHeight + dy
    }
  
    if (modal.width < 200) modal.width = 200
    if (modal.height < 100) modal.height = 100
  }
  
  function stopResize() {
    window.removeEventListener('mousemove', onResize)
    window.removeEventListener('mouseup', stopResize)
    resizeInfo = null
  }
  
  function modalStyle(modal) {
    return {
      position: 'fixed',
      left: modal.left + 'px',
      top: modal.top + 'px',
      width: modal.width + 'px',
      height: modal.minimized ? '40px' : modal.height + 'px',
      zIndex: modal.zIndex
    }
  }
  
  // -------------------- Настройки для фоновых частиц (3D) --------------------
  const bgConfig = {
    particleCount: 2000,
    spawnRadius: 10000,
    baseSpeedMin: 0.2,
    baseSpeedMax: 0.7,
    fadeOpacity: 0.2,
    sizeFactor: 200,
    bgColor: 'rgba(255, 255, 255, 1)',
    zSpawnRange: [-5, 5],
    zSpeedBase: 0.01
  }
  
  // -------------------- Настройки текста из частиц (WEBGL, 3D) --------------------
  const config = {
    particleCount: 4500,
    textArray: ["Мы (We)", "easy4"],
    mouseRadius: 0.1,
    particleSize: 2,
    forceMultiplier: 0.01,
    returnSpeed: 0.005,
    velocityDamping: 0.95,
    colorMultiplier: 80000,
    saturationMultiplier: 1000,
    textChangeInterval: 8000,
    rotationForceMultiplier: 0.1,
    baseHue: 0.0,
    textZPosition: -2,
    textZSpeed: 0.004
  }
  
  const bgCanvas = ref(null)
  let bgCtx
  const starParticles = []
  let bgAnimationFrameId
  
  function initBgParticles() {
    const w = bgCanvas.value.width
    const h = bgCanvas.value.height
    starParticles.length = 0
    const centerX = w / 2
    const centerY = h / 2
    for (let i = 0; i < bgConfig.particleCount; i++) {
      const angle = Math.random() * Math.PI * 2
      const r = Math.random() * bgConfig.spawnRadius
      const startX = centerX + Math.cos(angle) * r
      const startY = centerY + Math.sin(angle) * r
      const speed = Math.random() * (bgConfig.baseSpeedMax - bgConfig.baseSpeedMin) + bgConfig.baseSpeedMin
      const dirAngle = Math.random() * Math.PI * 2
      const z = Math.random() * (bgConfig.zSpawnRange[1] - bgConfig.zSpawnRange[0]) + bgConfig.zSpawnRange[0]
      starParticles.push({
        x: startX,
        y: startY,
        z: z,
        vx: Math.cos(dirAngle) * speed,
        vy: Math.sin(dirAngle) * speed,
        vz: bgConfig.zSpeedBase
      })
    }
  }
  
  function animateBg() {
    const w = bgCanvas.value.width
    const h = bgCanvas.value.height
    const centerX = w / 2
    const centerY = h / 2
  
    bgCtx.fillStyle = `rgba(0, 0, 0, ${bgConfig.fadeOpacity})`
    bgCtx.fillRect(0, 0, w, h)
  
    bgCtx.fillStyle = bgConfig.bgColor
    for (let i = 0; i < starParticles.length; i++) {
      const p = starParticles[i]
      p.x += p.vx
      p.y += p.vy
      p.z += p.vz
  
      const perspective = -1 / p.z
      const screenX = (p.x - centerX) * perspective
      const screenY = (p.y - centerY) * perspective
  
      const dx = p.x - centerX
      const dy = p.y - centerY
      const dist = Math.sqrt(dx * dx + dy * dy)
      const size = (1 + dist / bgConfig.sizeFactor) * perspective * 0.2
  
      bgCtx.beginPath()
      bgCtx.arc(screenX + centerX, screenY + centerY, Math.max(size, 0.5), 0, Math.PI * 2)
      bgCtx.fill()
  
      if (p.z > 0.1) {
        const angle = Math.random() * Math.PI * 2
        const r = Math.random() * bgConfig.spawnRadius
        p.x = centerX + Math.cos(angle) * r
        p.y = centerY + Math.sin(angle) * r
        p.z = Math.random() * (bgConfig.zSpawnRange[1] - bgConfig.zSpawnRange[0]) + bgConfig.zSpawnRange[0]
        const speed = Math.random() * (bgConfig.baseSpeedMax - bgConfig.baseSpeedMin) + bgConfig.baseSpeedMin
        const dirAngle = Math.random() * Math.PI * 2
        p.vx = Math.cos(dirAngle) * speed
        p.vy = Math.sin(dirAngle) * speed
        p.vz = bgConfig.zSpeedBase
      }
    }
  
    bgAnimationFrameId = requestAnimationFrame(animateBg)
  }
  
  const textCanvas = ref(null)
  let gl, nextTextTimeout
  let currentTextIndex = 0
  let textCoordinates = []
  
  const mouse = {
    x: -500,
    y: -500,
    radius: config.mouseRadius
  }
  
  const particles = []
  for (let i = 0; i < config.particleCount; i++) {
    particles.push({ x: 0, y: 0, z: config.textZPosition, baseX: 0, baseY: 0, vx: 0, vy: 0, vz: config.textZSpeed })
  }
  
  const vertexShaderSource = `
    attribute vec3 a_position;
    attribute float a_hue;
    attribute float a_saturation;
    varying float v_hue;
    varying float v_saturation;
    void main() {
      float perspective = 1.0 / (-a_position.z);
      gl_Position = vec4(a_position.x * perspective, a_position.y * perspective, 0.0, 1.0);
      gl_PointSize = ${config.particleSize.toFixed(1)} * perspective;
      v_hue = a_hue;
      v_saturation = a_saturation;
    }
  `
  
  const fragmentShaderSource = `
    precision mediump float;
    varying float v_hue;
    varying float v_saturation;
    uniform float u_baseHue;
    void main() {
      float hue = mod(v_hue + u_baseHue, 1.0);
      float c = hue * 6.0;
      float x = 1.0 - abs(mod(c, 2.0) - 1.0);
      vec3 color;
      if (c < 1.0) color = vec3(1.0, x, 0.0);
      else if (c < 2.0) color = vec3(x, 1.0, 0.0);
      else if (c < 3.0) color = vec3(0.0, 1.0, x);
      else if (c < 4.0) color = vec3(0.0, x, 1.0);
      else if (c < 5.0) color = vec3(x, 0.0, 1.0);
      else color = vec3(1.0, 0.0, x);
      vec3 finalColor = mix(vec3(1.0), color, v_saturation);
      gl_FragColor = vec4(finalColor, 1.0);
    }
  `
  
  function createShader(gl, type, source) {
    const shader = gl.createShader(type)
    gl.shaderSource(shader, source)
    gl.compileShader(shader)
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.error(gl.getShaderInfoLog(shader))
      gl.deleteShader(shader)
      return null
    }
    return shader
  }
  
  function createProgram(gl, vertexShader, fragmentShader) {
    const program = gl.createProgram()
    gl.attachShader(program, vertexShader)
    gl.attachShader(program, fragmentShader)
    gl.linkProgram(program)
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      console.error(gl.getProgramInfoLog(program))
      gl.deleteProgram(program)
      return null
    }
    return program
  }
  
  let program, positionAttributeLocation, hueAttributeLocation, saturationAttributeLocation, baseHueUniformLocation
  let positionBuffer, hueBuffer, saturationBuffer
  let positions, hues, saturations
  
  function getTextCoordinates(text) {
    const ctx = document.createElement("canvas").getContext("2d")
    ctx.canvas.width = textCanvas.value.width
    ctx.canvas.height = textCanvas.value.height
    const fontSize = Math.min(textCanvas.value.width / 4, textCanvas.value.height / 4)
    ctx.font = `900 ${fontSize}px Arial`
    ctx.fillStyle = "white"
    ctx.textAlign = "center"
    ctx.textBaseline = "middle"
    ctx.fillText(text, textCanvas.value.width / 2, textCanvas.value.height / 2)
    const imageData = ctx.getImageData(0, 0, textCanvas.value.width, textCanvas.value.height).data
    const coordinates = []
    for (let y = 0; y < textCanvas.value.height; y += 4) {
      for (let x = 0; x < textCanvas.value.width; x += 4) {
        const index = (y * textCanvas.value.width + x) * 4
        if (imageData[index + 3] > 128) {
          coordinates.push({
            x: (x / textCanvas.value.width) * 2 - 1,
            y: (y / textCanvas.value.height) * -2 + 1
          })
        }
      }
    }
    return coordinates
  }
  
  function createParticles() {
    textCoordinates = getTextCoordinates(config.textArray[currentTextIndex])
    for (let i = 0; i < config.particleCount; i++) {
      const randomIndex = Math.floor(Math.random() * textCoordinates.length)
      const { x, y } = textCoordinates[randomIndex]
      particles[i].x = particles[i].baseX = x
      particles[i].y = particles[i].baseY = y
      particles[i].z = config.textZPosition
      particles[i].vz = config.textZSpeed
    }
  }
  
  function updateParticles() {
    for (let i = 0; i < config.particleCount; i++) {
      const particle = particles[i]
      const dx = mouse.x - particle.x
      const dy = mouse.y - particle.y
      const distance = Math.sqrt(dx * dx + dy * dy)
      const forceDirectionX = distance !== 0 ? dx / distance : 0
      const forceDirectionY = distance !== 0 ? dy / distance : 0
      const maxDistance = mouse.radius
      const force = (maxDistance - distance) / maxDistance
      const directionX = forceDirectionX * force * config.forceMultiplier
      const directionY = forceDirectionY * force * config.forceMultiplier
  
      if (distance < mouse.radius) {
        const angle = Math.atan2(dy, dx)
        const rotationForceX = Math.sin(
          -Math.cos(angle * -1) *
          Math.sin(config.rotationForceMultiplier * Math.cos(force)) *
          Math.sin(distance * distance) *
          Math.sin(angle * distance)
        )
  
        const rotationForceY = Math.sin(
          Math.cos(angle * 1) *
          Math.sin(config.rotationForceMultiplier * Math.sin(force)) *
          Math.sin(distance * distance) *
          Math.cos(angle * distance)
        )
        particle.vx -= directionX + rotationForceX
        particle.vy -= directionY + rotationForceY
      } else {
        particle.vx += (particle.baseX - particle.x) * config.returnSpeed
        particle.vy += (particle.baseY - particle.y) * config.returnSpeed
      }
  
      particle.x += particle.vx
      particle.y += particle.vy
      particle.z += particle.vz
  
      particle.vx *= config.velocityDamping
      particle.vy *= config.velocityDamping
  
      if (particle.z > 0.2) {
        particle.z = config.textZPosition
      }
  
      const speed = Math.sqrt(particle.vx * particle.vx + particle.vy * particle.vy)
      const hue = ((speed * config.colorMultiplier) % 360) / 360.0
  
      hues[i] = hue
      saturations[i] = Math.min(speed * config.saturationMultiplier, 1)
      positions[i * 3] = particle.x
      positions[i * 3 + 1] = particle.y
      positions[i * 3 + 2] = particle.z
    }
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)
    gl.bufferData(gl.ARRAY_BUFFER, positions, gl.DYNAMIC_DRAW)
    gl.bindBuffer(gl.ARRAY_BUFFER, hueBuffer)
    gl.bufferData(gl.ARRAY_BUFFER, hues, gl.DYNAMIC_DRAW)
    gl.bindBuffer(gl.ARRAY_BUFFER, saturationBuffer)
    gl.bufferData(gl.ARRAY_BUFFER, saturations, gl.DYNAMIC_DRAW)
  }
  
  function animateText() {
    updateParticles()
    gl.clear(gl.COLOR_BUFFER_BIT)
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)
    gl.vertexAttribPointer(positionAttributeLocation, 3, gl.FLOAT, false, 0, 0)
    gl.enableVertexAttribArray(positionAttributeLocation)
  
    gl.bindBuffer(gl.ARRAY_BUFFER, hueBuffer)
    gl.vertexAttribPointer(hueAttributeLocation, 1, gl.FLOAT, false, 0, 0)
    gl.enableVertexAttribArray(hueAttributeLocation)
  
    gl.bindBuffer(gl.ARRAY_BUFFER, saturationBuffer)
    gl.vertexAttribPointer(saturationAttributeLocation, 1, gl.FLOAT, false, 0, 0)
    gl.enableVertexAttribArray(saturationAttributeLocation)
  
    gl.useProgram(program)
    gl.uniform1f(baseHueUniformLocation, config.baseHue)
  
    gl.drawArrays(gl.POINTS, 0, config.particleCount)
    requestAnimationFrame(animateText)
  }
  
  function changeText() {
    currentTextIndex = (currentTextIndex + 1) % config.textArray.length
    const newCoordinates = getTextCoordinates(config.textArray[currentTextIndex])
    for (let i = 0; i < config.particleCount; i++) {
      const randomIndex = Math.floor(Math.random() * newCoordinates.length)
      const { x, y } = newCoordinates[randomIndex]
      particles[i].baseX = x
      particles[i].baseY = y
    }
    nextTextTimeout = setTimeout(changeText, config.textChangeInterval)
  }
  
  function onKeyDown(event) {
  // Проверяем, нажата ли клавиша `~` (обычно это `Backquote` или `192`)
  // Также учитываем комбинацию с `Shift`, если необходимо
  if (event.key === '`' || event.key === 'Backquote') {
    event.preventDefault()
    toggleHalfLifeConsole()
  }
}

function toggleHalfLifeConsole() {
  const consoleIndex = modals.value.findIndex(modal => modal.component === HalfLifeConsole)
  if (consoleIndex !== -1) {
    // Если консоль уже открыта, закрываем её
    closeModal(consoleIndex)
  } else {
    // Иначе открываем консоль
    createModal('Консоль Easy4 OS', HalfLifeConsole)
  }
}

  
  onMounted(() => {
    window.addEventListener('keydown', onKeyDown)

    bgCanvas.value.width = window.innerWidth
    bgCanvas.value.height = window.innerHeight
    bgCtx = bgCanvas.value.getContext('2d')
    bgCtx.fillStyle = 'black'
    bgCtx.fillRect(0,0,bgCanvas.value.width,bgCanvas.value.height)
    initBgParticles()
    animateBg()
  
    gl = textCanvas.value.getContext("webgl", { alpha: true })
    textCanvas.value.width = window.innerWidth
    textCanvas.value.height = window.innerHeight
    gl.viewport(0, 0, textCanvas.value.width, textCanvas.value.height)
  
    const vShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource)
    const fShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource)
    program = createProgram(gl, vShader, fShader)
  
    positionAttributeLocation = gl.getAttribLocation(program, "a_position")
    hueAttributeLocation = gl.getAttribLocation(program, "a_hue")
    saturationAttributeLocation = gl.getAttribLocation(program, "a_saturation")
  
    positionBuffer = gl.createBuffer()
    hueBuffer = gl.createBuffer()
    saturationBuffer = gl.createBuffer()
  
    positions = new Float32Array(config.particleCount * 3)
    hues = new Float32Array(config.particleCount)
    saturations = new Float32Array(config.particleCount)
  
    baseHueUniformLocation = gl.getUniformLocation(program, "u_baseHue")
  
    gl.clearColor(0, 0, 0, 0)
  
    createParticles()
    animateText()
    nextTextTimeout = setTimeout(changeText, config.textChangeInterval)
  
    textCanvas.value.addEventListener("mousemove", (event) => {
      mouse.x = (event.clientX / textCanvas.value.width) * 2 - 1
      mouse.y = (event.clientY / textCanvas.value.height) * -2 + 1
    })
  
    textCanvas.value.addEventListener("mouseleave", () => {
      mouse.x = -500
      mouse.y = -500
    })
  
    window.addEventListener("resize", onResize)
  })
  
  onBeforeUnmount(() => {
    if (nextTextTimeout) clearTimeout(nextTextTimeout)
    cancelAnimationFrame(bgAnimationFrameId)
    window.removeEventListener("resize", onResize)
    window.removeEventListener('keydown', onKeyDown)

  })
  </script>
  
  <style>
  @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500");
  body {
    margin: 0;
    padding: 0;
    background: black;
    overflow: hidden;
    height: 100vh;
    font-family: Montserrat, sans-serif;
  }
  
  .container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  canvas:first-child {
    z-index: 1;
  }
  
  canvas:last-child {
    z-index: 2;
  }
  
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .menu-panel {
    position: fixed;
    top: 0;
    width: 100%;
    background: rgba(0,0,0,0.7);
    z-index: 9999;
    padding: 10px 0;
    text-align: center;
  }
  
  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    gap: 20px;
  }
  
  .menu li {
    cursor: pointer;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    transition: color 0.3s ease;
  }
  
  .menu li:hover {
    color: #00ff00;
  }
  
  .modal-window {
    background: #111;
    border: 1px solid #333;
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  
  .modal-header {
    background: #222;
    padding: 5px 10px;
    cursor: move;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    color: #fff;
    font-weight: bold;
    user-select: none;
  }
  
  .modal-buttons button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 5px;
    font-weight: bold;
  }
  
  .modal-buttons button:hover {
    color: #0f0;
  }
  
  .modal-body {
    background: #111;
    padding: 10px;
    flex: 1;
    overflow: auto;
    color: #ddd;
  }
  
  .resize-handle {
    position: absolute;
    background: transparent;
    z-index: 1000;
  }
  
  .resize-right {
    width: 5px;
    height: 100%;
    right: 0;
    top: 0;
    cursor: ew-resize;
  }
  
  .resize-bottom {
    height: 5px;
    width: 100%;
    bottom: 0;
    left: 0;
    cursor: ns-resize;
  }
  
  .resize-corner {
    width: 10px;
    height: 10px;
    right: 0;
    bottom: 0;
    cursor: nwse-resize;
  }
  
  @media (max-width: 768px) {
    .modal-window {
      left: 0 !important;
      top: 0 !important;
      width: 100vw !important;
      height: 100vh !important;
      border-radius: 0;
    }
  
    .resize-handle {
      display: none;
    }
  
    .modal-header {
      cursor: default;
    }
  
    .modal-buttons button:nth-child(1),
    .modal-buttons button:nth-child(2) {
      display: none;
    }
    .menu li{
      font-size: 10pt;
    }
  }
  </style>
  