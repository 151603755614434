// src/utils/db.js

// Функция для инициализации IndexedDB
function initDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('ConsoleE4DB', 1)

    request.onerror = (event) => {
      console.error('Ошибка при открытии IndexedDB', event)
      reject(event)
    }

    request.onsuccess = (event) => {
      resolve(event.target.result)
    }

    request.onupgradeneeded = (event) => {
      const db = event.target.result
      // Создаём хранилища объектов для файловой системы и пользователей
      if (!db.objectStoreNames.contains('fileSystem')) {
        db.createObjectStore('fileSystem', { keyPath: 'id' })
      }
      if (!db.objectStoreNames.contains('users')) {
        db.createObjectStore('users', { keyPath: 'username' })
      }
    }
  })
}

// Функция для получения файловой системы из IndexedDB
export async function getFileSystem() {
  const db = await initDB()
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['fileSystem'], 'readonly')
    const store = transaction.objectStore('fileSystem')
    const request = store.get('fs') // Используем фиксированный ключ 'fs'

    request.onsuccess = () => {
      resolve(request.result ? request.result.data : null)
    }

    request.onerror = (event) => {
      console.error('Ошибка при получении файловой системы', event)
      reject(event)
    }
  })
}

// Функция для сохранения файловой системы в IndexedDB
export async function setFileSystem(data) {
  const db = await initDB()
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['fileSystem'], 'readwrite')
    const store = transaction.objectStore('fileSystem')
    const request = store.put({ id: 'fs', data })

    request.onsuccess = () => {
      resolve()
    }

    request.onerror = (event) => {
      console.error('Ошибка при сохранении файловой системы', event)
      reject(event)
    }
  })
}

// Функция для получения списка пользователей из IndexedDB
export async function getUsers() {
  const db = await initDB()
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['users'], 'readonly')
    const store = transaction.objectStore('users')
    const request = store.getAll()

    request.onsuccess = () => {
      resolve(request.result)
    }

    request.onerror = (event) => {
      console.error('Ошибка при получении пользователей', event)
      reject(event)
    }
  })
}

// Функция для сохранения списка пользователей в IndexedDB
export async function setUsers(users) {
  const db = await initDB()
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['users'], 'readwrite')
    const store = transaction.objectStore('users')
    // Очищаем хранилище перед добавлением
    const clearRequest = store.clear()

    clearRequest.onsuccess = () => {
      // Добавляем всех пользователей
      users.forEach(user => {
        store.put(user)
      })
      resolve()
    }

    clearRequest.onerror = (event) => {
      console.error('Ошибка при очистке хранилища пользователей', event)
      reject(event)
    }
  })
}
