<template>
    <div>
      <h2>Информация о разработчиках</h2>
      <h3>Stas Norman</h3>
      <pre style="font-family: monospace; font-size: 16px; line-height: 16px; transform: scale(0.5); transform-origin: top left;">
  %%%%%%%%########%%#%@@@@@@@@@@%@%%%@@@@@@@@@@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%%%%#########%%%@%##%@@@@@@%%%@@@@@@@@@@@@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%%%%%######%%@@@#*++++**#%@@@@@@@@@@@@@@@@@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%%%%########@@@#*++++++++++**#%%@@@@@@@@@@@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%##%#%########@@%**+++++++++++++++***##%%%%%%@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%%%%%%%%%#%%@@%**+++++++++++++++++++*****#%@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %###%%%#%######%@@%#*+++++++++++++++++++++***#%@@@@%%%%%%%%%%%%%#%%%%%%%%%%%%%%%%%%%  
  %#######%%%####%@@%***++++++++++++++++++++****#%@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %##%%####%%%##%%@%***++++***++++++****###******#@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%############%@@***#%%%@@%%#****##@@@@@%###***@@#*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%########%%%%%@**##%@@@@%@%#++*%%%##%##%%#***#%#%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  #%%##########%%%#@***#%##*####*++****#####******#%#*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%###############**+*******+**++***+++++++++***###*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%#######%%%%###**++++++++**++***+++++++*****##*#%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%%%##%%%####%*****+++++++*++++***++++++****##**#%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%##%%%%%%%%%%####*#*++++++++#****##*+++++*****####%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%###%%###%%%%%%%##**+++++++*#####*+++********#%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%###%%%%######%%%#*****+++****##*************#%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%#%%%#%%%%####%#%%%#*******##%%##%@@%********##%%%%%%%%%%%%%%%%%%%%%%%%#%%%%%%%%%%%  
  %%%%%%%%%%%%%%%%%%%%%#********#**####*******####%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%##%%%%%%%%%%%##%%#********####********####%@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%%#####%%%%%%%%%%%%#*****+++++********#####%@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%%%%%%%%%%%%%%%%%%%%%##***********######**#@@@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%%%%%%%%%%%%%%%%%%%@@%####****######%#****@@@@@@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%%%%%%%%#%%%%%%%%%@@@@###############***#@@@@@@@@@%==+#%%%%%%%%%%%%%%%%%%%%%%%%%  
  %%%%%%%%%%#%%%%%%%%%%@@@@@%#############***@@@@@@@@@@@#=====*#%%%%%%%%%%%%%%%%%%%%%%  
  %%%###%%%####%%%%###%@@@@@@%###########**%@@@@@@@@@@@#=========+#%%%%%%%%%%%%%%%%%%%  
  %%%%%%%%%%%%#%%%#*+=#@@@@@@%%########**%@@@@@@@@@@@@%=============+#%%%%%%%%%%%%%%%%  
  %%%%%%%%####*+======*@@@@@%#%####****#@@@@@@@@@@@@%%=================+#%%%%%%%%%%%%%  
  %%%%%%###*==========*@@@@@@*##*****#@@@@@@@@@@@@@@%+==================*##%%%%%%%%%%%  
  %%%%#%%%+==========+%@@@%%*+=****@@@@@@@@@@@@@@@%%=================+%%%%%%%%%%%%%%%%  
  %%%%%%@+===========%%###***++====+++*%@@@@@@%@@@%================+%%%%%%%%%%%%%%%%%%  
  %%%%%@*==============++++++=======+++++++*###***================%%@@@%@@@@%%%%%%%%%%  
  %%%%@%========================================================+%@@@@@@@@@@@@%%%%%%%%  
  %%%@@=========================================================%@@@@@@@@@@@@@@@%%%%%%  
  %%@@%========================================================%@@@@@@@@@@@@@@@@@%%%%%  
  %%@@============================================*+==========%@@@@@@@@@@@@@@@@@@@%%%%  
  %@@#============================================#%+%*======%@@@@@@@@@@@@@@@@@@@@@%%%  
  %@@*=========================================+*==%+*#=====#@@@@@@@@@@@@@@@@@%@%#%@%%  
  @@@*===========================================#*%%%*====+@@@@@@@@@@@@@@@@@@%@@%#@@%  
  @@@%==========================================#=##*%%+===@@@@@@@@@@@@@@@@@@@@@%*%@@@  
  @@@@=========================================+=+=+***====@@@@@@@@@@@@@@@@@@@@%%@%@@@  
  @@@@*==============================***+**+*#*#####=======@@@@@@@@@@@@@@@@@@@@@@@@@@@  
  @@@@@++===========================+*=====+++++**#*#*====+@@@@@@@@@@@@@@@@@@@@@@@@@@@ 
      </pre>
    </div>
  </template>
  
  <script setup>

  </script>
  
  <style scoped>
  h2 {
    color: #fff;
    margin-bottom: 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    color: #ddd;
    background: #222;
  }
  
  th, td {
    border: 1px solid #333;
    padding: 8px;
    text-align: left;
  }
  </style>
  