<!-- HalfLifeConsole.vue -->
<template>
  <div class="console-container">
    <div class="console-header">
      <span>Console</span>
    </div>
    <div class="console-body">
      <pre class="console-output" ref="consoleOutput" v-html="output"></pre>
      <div v-if="isEditing" class="edit-mode">
        <div class="editor-preview">
          <textarea
            v-model="editBuffer"
            class="edit-textarea"
            placeholder="Enter Markdown here..."
          ></textarea>
          <!-- Добавляем ref="previewPane" для доступа к DOM-элементу -->
          <div class="preview-pane" ref="previewPane" v-html="renderedMarkdown"></div>
        </div>
        <div class="edit-controls">
          <button @click="saveEdit">Сохранить</button>
          <button @click="cancelEdit">Отмена</button>
        </div>
      </div>
      <div v-else>
        <input
          v-model="command"
          @keyup.enter="executeCommand"
          class="console-input"
          placeholder="Enter command..."
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted, toRaw, computed, watch } from 'vue'
import axios from 'axios'
import { getFileSystem, setFileSystem, getUsers, setUsers } from '../utils/db'
import { marked } from 'marked' // Импортируем библиотеку marked
import mermaid from 'mermaid' // Импортируем библиотеку mermaid

// Инициализация Mermaid
mermaid.initialize({ startOnLoad: false })

// Текущее содержимое консоли
const output = ref(`# Welcome to Console E4
>_ `)

// Текущая команда
const command = ref('')

// Ссылка на элемент вывода
const consoleOutput = ref(null)

// Ссылка на элемент предварительного просмотра
const previewPane = ref(null)

// Текущий путь
const currentPath = ref(['/'])

// Файловая система
const fileSystem = ref({})

// Список пользователей [{ username, passwordHash }]
const users = ref([])

// Текущий залогиненный пользователь
let loggedInUser = ref(null)

// Состояние для редактирования
const isEditing = ref(false)
const fileBeingEdited = ref(null)
const editBuffer = ref('')

// Вычисляемое свойство для рендеринга Markdown
const renderedMarkdown = computed(() => {
  const rawMarkdown = editBuffer.value || ''
  const html = marked.parse(rawMarkdown)
  return html
})

// Watcher для инициализации Mermaid после рендеринга Markdown
watch(renderedMarkdown, async () => {
  await nextTick()
  if (mermaid && previewPane.value) {
    console.log('Попытка инициализировать Mermaid...')
    try {
      await mermaid.init(undefined, previewPane.value)
      console.log('Mermaid диаграммы инициализированы.')
    } catch (error) {
      console.error('Ошибка при инициализации Mermaid:', error)
      output.value += `\nОшибка при инициализации Mermaid: ${error.message || error}`
    }
  }
})

// Инициализация файловой системы
function initializeFileSystem() {
  fileSystem.value = {
    name: '/',
    type: 'directory',
    children: [
      {
        name: 'home',
        type: 'directory',
        children: [
          {
            name: 'user',
            type: 'directory',
            children: []
          }
        ]
      },
      {
        name: 'var',
        type: 'directory',
        children: []
      },
      {
        name: 'etc',
        type: 'directory',
        children: []
      },
      { name: 'readme.md', type: 'file', content: 'Добро пожаловать в консоль!' }
    ]
  }
}

// Загрузить файловую систему и пользователей
async function loadFileSystem() {
  try {
    const storedFs = await getFileSystem()
    const storedUsers = await getUsers()
    if (storedFs) {
      fileSystem.value = storedFs
    } else {
      initializeFileSystem()
      await saveFileSystem()
    }
    if (storedUsers) {
      users.value = storedUsers
    } else {
      users.value = []
      await saveUsers()
    }

    // Проверка, есть ли сохранённый пользователь в localStorage
    const storedUser = localStorage.getItem('loggedInUser')
    if (storedUser) {
      loggedInUser.value = JSON.parse(storedUser)
      currentPath.value = ['/', 'home', 'user', loggedInUser.value.username]
      output.value += `\nВы автоматически вошли как "${loggedInUser.value.username}".`
    }
  } catch (error) {
    console.error('Ошибка при загрузке файловой системы и пользователей:', error)
    output.value += `\nОшибка при загрузке файловой системы и пользователей: ${error.message || error}`
  }
}

// Сохранить файловую систему
async function saveFileSystem() {
  try {
    await setFileSystem(toRaw(fileSystem.value))
  } catch (error) {
    console.error('Ошибка при сохранении файловой системы:', error)
    output.value += `\nОшибка при сохранении файловой системы: ${error.message || error}`
  }
}

// Сохранить пользователей
async function saveUsers() {
  try {
    await setUsers(toRaw(users.value))
  } catch (error) {
    console.error('Ошибка при сохранении пользователей:', error)
    output.value += `\nОшибка при сохранении пользователей: ${error.message || error}`
  }
}

// Получить текущую директорию
function getCurrentDirectory() {
  let dir = fileSystem.value
  for (let i = 1; i < currentPath.value.length; i++) {
    const folder = dir.children.find(child => child.name === currentPath.value[i] && child.type === 'directory')
    if (folder) {
      dir = folder
    } else {
      return null
    }
  }
  return dir
}

// Разрешить относительный или абсолютный путь
function resolvePath(path) {
  if (path.startsWith('/')) {
    const parts = path.split('/').filter(p => p)
    return ['/', ...parts]
  } else {
    const newPath = [...currentPath.value]
    const parts = path.split('/')
    for (const part of parts) {
      if (part === '..') {
        if (newPath.length > 1) newPath.pop()
      } else if (part !== '.' && part !== '') {
        newPath.push(part)
      }
    }
    return newPath
  }
}

// Фильтрация содержимого home/user в зависимости от залогиненного пользователя
function getFilteredChildren(dir) {
  if (dir.name === 'user' && currentPath.value.includes('user')) {
    // Если мы внутри user и есть залогиненный пользователь, показываем только его директорию
    if (loggedInUser.value) {
      return dir.children.filter(child => child.name === loggedInUser.value.username)
    } else {
      // Если нет логина, ничего не показываем
      return []
    }
  } 
  return dir.children
}

// Хэширование пароля (SHA-256)
async function hashPassword(password) {
  const encoder = new TextEncoder()
  const data = encoder.encode(password)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

// Функция для получения директории по пути
function getDirectoryByPath(pathArray) {
  let dir = fileSystem.value
  for (let i = 1; i < pathArray.length; i++) {
    const folder = dir.children.find(child => child.name === pathArray[i] && child.type === 'directory')
    if (folder) {
      dir = folder
    } else {
      return null
    }
  }
  return dir
}

// Команды
const commands = {
  help: {
    description: 'Показать список доступных команд',
    response: () => `
help - Показать список доступных команд
ls - Показать содержимое текущей директории
cd - Перейти в указанную директорию
pwd - Показать текущий путь
mkdir - Создать новую директорию
touch - Создать новый файл
cat - Просмотреть содержимое файла
write - Записать содержимое в файл, заменив существующее
rm - Удалить файл или директорию
tree - Показать структуру файловой системы
about - Информация о консоли
easteregg - Показать секретное сообщение
ip - Получить информацию по своему IP
tracert - Выполнить traceroute до указанного хоста
dns - Получить DNS-записи для домена
ping - Проверить доступность хоста
whois - Получить информацию о регистрации домена
append - Добавить текст в конец файла
mv - Переместить или переименовать файл/директорию
cp - Скопировать файл
grep - Поиск паттерна в файле
clear - Очистить экран консоли
login - Авторизоваться или создать нового пользователя
logout - Выйти из текущей сессии
edit - Открыть файл для редактирования
download - Скачать файл на ПК
  `
  }, // Запятая здесь
  ls: {
    description: 'Показать содержимое текущей директории',
    response: () => {
      const dir = getCurrentDirectory()
      if (!dir) {
        return 'Ошибка: Невозможно получить текущую директорию.'
      }
      const children = getFilteredChildren(dir)
      if (children.length === 0) {
        return 'Пустая директория.'
      }
      return children.map(item => item.name).join('  ')
    }
  }, // Запятая здесь
  cd: {
    description: 'Перейти в указанную директорию',
    response: (args) => {
      if (args.length === 0) {
        return 'Использование: cd <путь>'
      }
      const path = args[0]
      const newPath = resolvePath(path)
      const dir = getDirectoryByPath(newPath)
      if (!dir) {
        return `Нет такой директории: ${path}`
      }
      // Проверяем, не заходим ли мы в home/user без авторизации
      if (newPath.length >= 3 && newPath[1] === 'home' && newPath[2] === 'user') {
        if (!loggedInUser.value) {
          return `Вы не авторизованы. Используйте команду: login <username> <password>`
        }
        // Автоматически переключаемся на директорию пользователя
        if (newPath.length === 3) { // Если пользователь просто пытается зайти в /home/user
          currentPath.value = ['/', 'home', 'user', loggedInUser.value.username]
          return `Переключено на директорию пользователя "${loggedInUser.value.username}".`
        }
      }
      currentPath.value = newPath
      return ''
    }
  }, // Запятая здесь
  pwd: {
    description: 'Показать текущий путь',
    response: () => {
      if (currentPath.value.length === 1 && currentPath.value[0] === '/') {
        return '/'
      }
      return '/' + currentPath.value.slice(1).join('/')
    }
  }, // Запятая здесь
  mkdir: {
    description: 'Создать новую директорию',
    response: (args) => {
      if (args.length === 0) {
        return 'Использование: mkdir <имя_директории>'
      }
      const dirname = args[0]
      const currentDir = getCurrentDirectory()
      if (!currentDir) {
        return 'Ошибка: Невозможно получить текущую директорию.'
      }
      if (currentDir.children.find(child => child.name === dirname)) {
        return `Директория или файл с именем "${dirname}" уже существует.`
      }
      currentDir.children.push({
        name: dirname,
        type: 'directory',
        children: []
      })
      saveFileSystem()
      return `Директория "${dirname}" создана.`
    }
  }, // Запятая здесь
  touch: {
    description: 'Создать новый файл',
    response: (args) => {
      if (args.length === 0) {
        return 'Использование: touch <имя_файла> [содержимое]'
      }
      const filename = args[0]
      const content = args.slice(1).join(' ') || ''
      const currentDir = getCurrentDirectory()
      if (!currentDir) {
        return 'Ошибка: Невозможно получить текущую директорию.'
      }
      if (currentDir.children.find(child => child.name === filename)) {
        return `Директория или файл с именем "${filename}" уже существует.`
      }
      currentDir.children.push({
        name: filename,
        type: 'file',
        content: content
      })
      saveFileSystem()
      return `Файл "${filename}" создан.`
    }
  }, // Запятая здесь
  cat: {
    description: 'Просмотреть содержимое файла',
    response: (args) => {
      if (args.length === 0) {
        return 'Использование: cat <имя_файла>'
      }
      const filename = args[0]
      const currentDir = getCurrentDirectory()
      if (!currentDir) {
        return 'Ошибка: Невозможно получить текущую директорию.'
      }
      const file = currentDir.children.find(child => child.name === filename && child.type === 'file')
      if (!file) {
        return `Нет такого файла: ${filename}`
      }
      return file.content || ''
    }
  }, // Запятая здесь
  write: {
    description: 'Записать содержимое в файл, заменив существующее',
    response: (args) => {
      if (args.length < 2) {
        return 'Использование: write <имя_файла> <содержимое>'
      }
      const filename = args[0]
      const content = args.slice(1).join(' ')
      const currentDir = getCurrentDirectory()
      if (!currentDir) {
        return 'Ошибка: Невозможно получить текущую директорию.'
      }
      const file = currentDir.children.find(child => child.name === filename && child.type === 'file')
      if (!file) {
        return `Нет такого файла: ${filename}`
      }
      file.content = content
      saveFileSystem()
      return `Содержимое файла "${filename}" обновлено.`
    }
  }, // Запятая здесь
  rm: {
    description: 'Удалить файл или директорию',
    response: (args) => {
      if (args.length === 0) {
        return 'Использование: rm <имя_файла/директории>'
      }
      const name = args[0]
      const currentDir = getCurrentDirectory()
      if (!currentDir) {
        return 'Ошибка: Невозможно получить текущую директорию.'
      }
      const index = currentDir.children.findIndex(child => child.name === name)
      if (index === -1) {
        return `Нет такого файла или директории: ${name}`
      }
      currentDir.children.splice(index, 1)
      saveFileSystem()
      return `Удалено: ${name}`
    }
  }, // Запятая здесь
  tree: {
    description: 'Показать структуру файловой системы',
    response: () => {
      const dir = getCurrentDirectory()
      if (!dir) {
        return 'Ошибка: Невозможно получить текущую директорию.'
      }

      const buildTree = (dir, indent = '') => {
        let treeStr = `${indent}${dir.name}/\n`
        let children = dir.children
        if (dir.name === 'user' && currentPath.value.includes('user') && loggedInUser.value) {
          // Фильтруем только директорию залогиненного юзера
          children = dir.children.filter(child => child.name === loggedInUser.value.username)
        }
        children.forEach(child => {
          if (child.type === 'directory') {
            treeStr += buildTree(child, indent + '  ')
          } else {
            treeStr += `${indent}  ${child.name}\n`
          }
        })
        return treeStr
      }

      return buildTree(dir)
    }
  }, // Запятая здесь
  about: {
    description: 'Информация о консоли',
    response: () => `Console E4 v1.0
Разработано для демонстрации возможностей Vue.js и IndexedDB`
  }, // Запятая здесь
  easteregg: {
    description: 'Показать секретное сообщение',
    response: () => `Секретное сообщение!`
  }, // Запятая здесь
  ip: {
    description: 'Получить информацию по своему IP',
    response: async () => {
      try {
        const ipResponse = await axios.get('https://ipapi.co/json/')
        const data = ipResponse.data
        let ipInfo = `IP: ${data.ip}\n`
        ipInfo += `Город: ${data.city}\n`
        ipInfo += `Регион: ${data.region}\n`
        ipInfo += `Страна: ${data.country_name}\n`
        ipInfo += `Широта и долгота: ${data.latitude}, ${data.longitude}\n`
        ipInfo += `Почтовый индекс: ${data.postal}\n`
        ipInfo += `Организация: ${data.org}`
        return ipInfo
      } catch (error) {
        console.error('Ошибка при получении информации по IP:', error)
        return 'Ошибка при получении информации по IP.'
      }
    }
  }, // Запятая здесь
  tracert: {
    description: 'Выполнить traceroute до указанного хоста',
    response: async (args) => {
      if (args.length === 0) {
        return 'Использование: tracert <хост>'
      }
      const host = args[0]
      try {
        const tracertResponse = await axios.get(`http://localhost:3000/tracert`, {
          params: { host }
        })
        const result = tracertResponse.data
        return result
      } catch (error) {
        console.error('Ошибка при выполнении traceroute:', error)
        return 'Ошибка при выполнении traceroute.'
      }
    }
  }, // Запятая здесь
  dns: {
    description: 'Получить DNS-записи для домена',
    response: async (args) => {
      if (args.length === 0) {
        return 'Использование: dns <домен>'
      }
      const domain = args[0]
      try {
        const dnsResponse = await axios.get('https://cloudflare-dns.com/dns-query', {
          params: { name: domain, type: 'A' },
          headers: { 'Accept': 'application/dns-json' }
        })
        const data = dnsResponse.data
        if (!data.Answer) {
          return `Нет DNS-записей для ${domain}.`
        }
        let dnsInfo = `DNS-записи для ${domain}:\n`
        data.Answer.forEach(record => {
          dnsInfo += `${record.type} ${record.name} -> ${record.data}\n`
        })
        return dnsInfo
      } catch (error) {
        console.error('Ошибка при получении DNS-записей:', error)
        return 'Ошибка при получении DNS-записей.'
      }
    }
  }, // Запятая здесь
  ping: {
    description: 'Проверить доступность хоста',
    response: async (args) => {
      if (args.length === 0) {
        return 'Использование: ping <хост>'
      }
      const host = args[0]
      try {
        const pingResponse = await axios.get(`http://localhost:3000/ping`, {
          params: { host }
        })
        const result = pingResponse.data
        return result
      } catch (error) {
        console.error('Ошибка при выполнении ping:', error)
        return 'Ошибка при выполнении ping.'
      }
    }
  }, // Запятая здесь
  whois: {
    description: 'Получить информацию о регистрации домена',
    response: async (args) => {
      if (args.length === 0) {
        return 'Использование: whois <домен>'
      }
      const domain = args[0]
      try {
        const apiKey = 'YOUR_JSONWHOISAPI_KEY' // Замените на ваш API ключ
        const whoisResponse = await axios.get(`https://api.jsonwhoisapi.com/v1/whois/${domain}`, {
          headers: { 'Authorization': `Bearer ${apiKey}` }
        })
        const data = whoisResponse.data
        if (data.Error) {
          return `Ошибка: ${data.Error}`
        }
        let whoisInfo = `Информация о домене ${domain}:\n`
        whoisInfo += `Регистратор: ${data.registrar.name}\n`
        whoisInfo += `Дата регистрации: ${data.createdDate}\n`
        whoisInfo += `Дата истечения срока: ${data.expirationDate}\n`
        whoisInfo += `Статус: ${data.status.join(', ')}\n`
        whoisInfo += `Владелец: ${data.registrant.name || 'Не указано'}\n`
        return whoisInfo
      } catch (error) {
        console.error('Ошибка при выполнении whois:', error)
        return 'Ошибка при выполнении whois.'
      }
    }
  }, // Запятая здесь
  append: {
    description: 'Добавить текст в конец файла',
    response: (args) => {
      if (args.length < 2) {
        return 'Использование: append <имя_файла> <текст>'
      }
      const filename = args[0]
      const text = args.slice(1).join(' ')
      const currentDir = getCurrentDirectory()
      if (!currentDir) return 'Ошибка: Невозможно получить текущую директорию.'
      const file = currentDir.children.find(child => child.name === filename && child.type === 'file')
      if (!file) {
        return `Нет такого файла: ${filename}`
      }
      file.content += (file.content ? '\n' : '') + text
      saveFileSystem()
      return `Текст добавлен в "${filename}".`
    }
  }, // Запятая здесь
  mv: {
    description: 'Переместить или переименовать файл/директорию',
    response: (args) => {
      if (args.length < 2) {
        return 'Использование: mv <источник> <назначение>'
      }
      const source = args[0]
      const destination = args[1]

      const currentDir = getCurrentDirectory()
      if (!currentDir) return 'Ошибка: Невозможно получить текущую директорию.'
      const index = currentDir.children.findIndex(child => child.name === source)
      if (index === -1) {
        return `Нет такого файла или директории: ${source}`
      }
      const item = currentDir.children[index]

      const destPath = resolvePath(destination)
      const destDir = getDirectoryByPath(destPath.slice(0, -1)) 
      const newName = destPath[destPath.length - 1]

      if (!destDir) {
        return `Директория назначения не найдена.`
      }

      if (destDir.children.find(c => c.name === newName)) {
        return `Файл или директория "${newName}" уже существует в назначении.`
      }

      currentDir.children.splice(index, 1) 
      item.name = newName 
      destDir.children.push(item)
      saveFileSystem()
      return `Перемещено/Переименовано: ${source} -> ${destination}`
    }
  }, // Запятая здесь
  cp: {
    description: 'Скопировать файл',
    response: (args) => {
      if (args.length < 2) {
        return 'Использование: cp <источник> <назначение>'
      }
      const source = args[0]
      const destination = args[1]

      const currentDir = getCurrentDirectory()
      if (!currentDir) return 'Ошибка: Невозможно получить текущую директорию.'

      const sourceFile = currentDir.children.find(child => child.name === source && child.type === 'file')
      if (!sourceFile) {
        return `Нет такого файла: ${source} или копирование директорий не поддерживается`
      }

      const destPath = resolvePath(destination)
      const destDir = getDirectoryByPath(destPath.slice(0, -1))
      const newName = destPath[destPath.length - 1]

      if (!destDir) {
        return `Директория назначения не найдена.`
      }

      if (destDir.children.find(c => c.name === newName)) {
        return `Файл или директория "${newName}" уже существует в назначении.`
      }

      destDir.children.push({
        name: newName,
        type: 'file',
        content: sourceFile.content
      })
      saveFileSystem()
      return `Файл "${source}" скопирован в "${destination}".`
    }
  }, // Запятая здесь
  grep: {
    description: 'Поиск паттерна в файле',
    response: (args) => {
      if (args.length < 2) {
        return 'Использование: grep <паттерн> <имя_файла>'
      }
      const pattern = args[0]
      const filename = args[1]
      const currentDir = getCurrentDirectory()
      if (!currentDir) {
        return 'Ошибка: Невозможно получить текущую директорию.'
      }
      const file = currentDir.children.find(child => child.name === filename && child.type === 'file')
      if (!file) {
        return `Нет такого файла: ${filename}`
      }
      const lines = file.content.split('\n')
      const matched = lines.filter(line => line.includes(pattern))
      if (matched.length === 0) {
        return 'Совпадений не найдено.'
      }
      return matched.join('\n')
    }
  }, // Запятая здесь
  clear: {
    description: 'Очистить экран консоли',
    response: () => {
      output.value = `# Welcome to Console E4\n>_ `
      return ''
    }
  }, // Запятая здесь
  login: {
    description: 'Авторизоваться или создать нового пользователя',
    response: async (args) => {
      if (args.length < 2) {
        return 'Использование: login <username> <password>'
      }
      const username = args[0]
      const password = args[1]
      const user = users.value.find(u => u.username === username)
      const passwordHash = await hashPassword(password)
      if (!user) {
        // Создаём нового пользователя
        users.value.push({ username, passwordHash })
        await saveUsers()
        // Создаём директорию для пользователя
        const userDir = getDirectoryByPath(['/', 'home', 'user'])
        if (userDir) {
          userDir.children.push({
            name: username,
            type: 'directory',
            children: [
              {
                name: 'notes.md',
                type: 'file',
                content: '# Мои Заметки\n\nДобро пожаловать! Это ваши личные заметки.'
              }
            ]
          })
          await saveFileSystem()
        }
        loggedInUser.value = { username }
        currentPath.value = ['/', 'home', 'user', username]
        localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser.value))
        return `Пользователь "${username}" создан и залогинен. Переключено на вашу директорию.`
      } else {
        // Проверяем пароль
        if (user.passwordHash === passwordHash) {
          loggedInUser.value = { username }
          // Автоматически переключаемся на директорию пользователя
          currentPath.value = ['/', 'home', 'user', username]
          localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser.value))
          return `Успешный вход: ${username}. Переключено на вашу директорию.`
        } else {
          return 'Неверный пароль.'
        }
      }
    }
  }, // Запятая здесь
  logout: {
    description: 'Выйти из текущей сессии',
    response: () => {
      if (loggedInUser.value) {
        const username = loggedInUser.value.username
        loggedInUser.value = null
        localStorage.removeItem('loggedInUser')
        currentPath.value = ['/']
        return `Пользователь "${username}" вышел из системы.`
      } else {
        return 'Вы не залогинены.'
      }
    }
  }, // Запятая здесь
  edit: {
    description: 'Открыть файл для редактирования с поддержкой Markdown и Mermaid диаграмм',
    response: (args) => {
      if (args.length === 0) {
        return 'Использование: edit <имя_файла>'
      }
      const filename = args[0]
      const currentDir = getCurrentDirectory()
      if (!currentDir) {
        return 'Ошибка: Невозможно получить текущую директорию.'
      }
      const file = currentDir.children.find(child => child.name === filename && child.type === 'file')
      if (!file) {
        return `Нет такого файла: ${filename}`
      }
      isEditing.value = true
      fileBeingEdited.value = file
      editBuffer.value = file.content
      return `Редактирование файла "${filename}". Введите Markdown или Mermaid разметку и нажмите "Сохранить".`
    }
  }, // Запятая здесь
  download: {
    description: 'Скачать файл на ПК',
    response: (args) => {
      if (args.length === 0) {
        return 'Использование: download <имя_файла>'
      }
      const filename = args[0]
      const currentDir = getCurrentDirectory()
      if (!currentDir) {
        return 'Ошибка: Невозможно получить текущую директорию.'
      }
      const file = currentDir.children.find(child => child.name === filename && child.type === 'file')
      if (!file) {
        return `Нет такого файла: ${filename}`
      }

      try {
        // Создание Blob из содержимого файла
        const blob = new Blob([file.content], { type: 'text/markdown' })
        const url = URL.createObjectURL(blob)

        // Создание временной ссылки для скачивания
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url)

        return `Файл "${filename}" скачан на ваш ПК.`
      } catch (error) {
        console.error('Ошибка при скачивании файла:', error)
        return `Ошибка при скачивании файла "${filename}": ${error.message || error}`
      }
    }
  } // Последняя команда без запятой
}

// Функция для сохранения редактирования
function saveEdit() {
  if (fileBeingEdited.value && editBuffer.value !== null) {
    fileBeingEdited.value.content = editBuffer.value
    saveFileSystem()
    output.value += `\nФайл "${fileBeingEdited.value.name}" сохранён.`
    isEditing.value = false
    fileBeingEdited.value = null
    editBuffer.value = ''
  }
}

// Функция для отмены редактирования
function cancelEdit() {
  isEditing.value = false
  fileBeingEdited.value = null
  editBuffer.value = ''
  output.value += `\nРедактирование отменено.`
}

// Функция для выполнения команды
async function executeCommand() {
  const input = command.value.trim()
  if (input === '') return

  if (isEditing.value) {
    output.value += `\nВы находитесь в режиме редактирования. Используйте кнопки "Сохранить" или "Отмена".`
    command.value = ''
    return
  }

  const parts = input.split(' ').filter(part => part !== '')
  const cmd = parts[0].toLowerCase()
  const args = parts.slice(1)

  output.value += `\n> ${input}`

  const foundCommand = commands[cmd]

  if (foundCommand) {
    try {
      const response = foundCommand.response(args)
      if (response instanceof Promise) {
        const asyncResponse = await response
        if (asyncResponse) {
          output.value += `\n${asyncResponse}`
        }
      } else {
        if (response) {
          output.value += `\n${response}`
        }
      }
    } catch (err) {
      console.error(`Ошибка при выполнении команды ${cmd}:`, err)
      output.value += `\nОшибка при выполнении команды ${cmd}: ${err.message || 'Неизвестная ошибка.'}`
    }
  } else {
    output.value += `\nКоманда не найдена: ${cmd}`
  }

  command.value = ''
  nextTick(() => {
    if (consoleOutput.value) {
      consoleOutput.value.scrollTop = consoleOutput.value.scrollHeight
    }
  })
}

onMounted(async () => {
  await loadFileSystem()
})
</script>

<style scoped>
.console-container {
  background: #000;
  color: #0f0;
  font-family: 'Courier New', Courier, monospace;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.console-header {
  background: #111;
  padding: 10px;
  border-bottom: 1px solid #0f0;
  text-align: center;
  font-weight: bold;
}

.console-body {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.console-output {
  flex: 1;
  background: #000;
  color: #0f0;
  border: none;
  resize: none;
  outline: none;
  overflow-y: auto;
  white-space: pre-wrap; 
}

.console-input {
  background: #000;
  color: #0f0;
  border: none;
  border-top: 1px solid #0f0;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
  outline: none;
}

.edit-mode {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.editor-preview {
  display: flex;
  gap: 10px;
  flex: 1;
}

.edit-textarea {
  width: 50%;
  height: 300px;
  background: #000;
  color: #0f0;
  border: 1px solid #0f0;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
  resize: vertical;
  outline: none;
}

.preview-pane {
  width: 50%;
  height: 300px;
  background: #1a1a1a;
  color: #fff;
  border: 1px solid #0f0;
  padding: 10px;
  overflow-y: auto;
  font-family: Arial, sans-serif;
}

.preview-pane svg {
  max-width: 100%;
  height: auto;
}

.edit-controls {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.edit-controls button {
  background: #111;
  color: #0f0;
  border: 1px solid #0f0;
  padding: 5px 10px;
  cursor: pointer;
}

.edit-controls button:hover {
  background: #0f0;
  color: #000;
}
</style>
